import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step26 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError73, setError73] = useState(false);
    const [isError74, setError74] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		defaultValues: {
			['Обязанности Менеджера по персоналу']: data['Обязанности Менеджера по персоналу'],
			['Какие параметры являются ключевыми при приеме исполнителя на работу?']: data['Какие параметры являются ключевыми при приеме исполнителя на работу?'],
		},
	});

	
	const ckbox73 = watch('Обязанности Менеджера по персоналу');
    const ckbox74 = watch('Какие параметры являются ключевыми при приеме исполнителя на работу?');
	
	useEffect(() => {
		if (!ckbox73?.length) {
			setError73(true);
		} else {
			setError73(false);
		}
	}, [ckbox73]);

    useEffect(() => {
		if (!ckbox74?.length) {
			setError74(true);
		} else {
			setError74(false);
		}
	}, [ckbox74]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError73 && !isError74 ) {
			dispatch(setAddStep(27));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(26));
		}
	};

	return (
		<div className={s.step26}>
      <div className={s.wrapper_heading}>
			  <h5>Исполнители. Менеджер по персоналу</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					73. Обязанности Менеджера по персоналу
					</h4>

					<div className={s.question73}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question73,
								})}
								{...register('Обязанности Менеджера по персоналу')}
								id='exam731'
								type='checkbox'
								name='Обязанности Менеджера по персоналу'
								autoComplete='off'
								value='Прием звонков от клиентов'/>
							<label htmlFor='exam731' className={s.label}>
							Прием звонков от клиентов
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question73,
								})}
								{...register('Обязанности Менеджера по персоналу')}
								id='exam732'
								type='checkbox'
								name='Обязанности Менеджера по персоналу'
								autoComplete='off'
								value='Обработка входящих звонков от соискателей'/>
							<label htmlFor='exam732' className={s.label}>
							Обработка входящих звонков от соискателей
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question73,
								})}
								{...register('Обязанности Менеджера по персоналу')}
								id='exam733'
								type='checkbox'
								name='Обязанности Менеджера по персоналу'
								autoComplete='off'
								value='Проведение собеседований'/>
							<label htmlFor='exam733' className={s.label}>
							Проведение собеседований
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question73,
								})}
								{...register('Обязанности Менеджера по персоналу')}
								id='exam734'
								type='checkbox'
								name='Обязанности Менеджера по персоналу'
								autoComplete='off'
								value='Контроль выполнения заказа удаленно'/>
							<label htmlFor='exam734' className={s.label}>
							Контроль выполнения заказа удаленно
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question73,
								})}
								{...register('Обязанности Менеджера по персоналу')}
								id='exam735'
								type='checkbox'
								name='Обязанности Менеджера по персоналу'
								autoComplete='off'
								value='Присутствие с бригадой на объекте'/>
							<label htmlFor='exam735' className={s.label}>
							Присутствие с бригадой на объекте
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question73,
								})}
								{...register('Обязанности Менеджера по персоналу')}
								id='exam736'
								type='checkbox'
								name='Обязанности Менеджера по персоналу'
								autoComplete='off'
								value='Выплаты з/п, премий, штрафов'/>
							<label htmlFor='exam736' className={s.label}>
							Выплаты з/п, премий, штрафов
							</label>
						</div>

						<p className={s.errorText2}>
							{isError73 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					74. Какие параметры являются ключевыми при приеме исполнителя на работу?
					</h4>

					<div className={s.question74}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question74,
								})}
								{...register('Какие параметры являются ключевыми при приеме исполнителя на работу?')}
								id='exam741'
								type='checkbox'
								name='Какие параметры являются ключевыми при приеме исполнителя на работу?'
								autoComplete='off'
								value='Семейное положение'/>
							<label htmlFor='exam741' className={s.label}>
							Семейное положение
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question74,
								})}
								{...register('Какие параметры являются ключевыми при приеме исполнителя на работу?')}
								id='exam742'
								type='checkbox'
								name='Какие параметры являются ключевыми при приеме исполнителя на работу?'
								autoComplete='off'
								value='Возраст'/>
							<label htmlFor='exam742' className={s.label}>
							Возраст
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question74,
								})}
								{...register('Какие параметры являются ключевыми при приеме исполнителя на работу?')}
								id='exam743'
								type='checkbox'
								name='Какие параметры являются ключевыми при приеме исполнителя на работу?'
								autoComplete='off'
								value='Наличие основной работы'/>
							<label htmlFor='exam743' className={s.label}>
							Наличие основной работы
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question74,
								})}
								{...register('Какие параметры являются ключевыми при приеме исполнителя на работу?')}
								id='exam744'
								type='checkbox'
								name='Какие параметры являются ключевыми при приеме исполнителя на работу?'
								autoComplete='off'
								value='Гражданство'/>
							<label htmlFor='exam744' className={s.label}>
							Гражданство
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question74,
								})}
								{...register('Какие параметры являются ключевыми при приеме исполнителя на работу?')}
								id='exam745'
								type='checkbox'
								name='Какие параметры являются ключевыми при приеме исполнителя на работу?'
								autoComplete='off'
								value='Судимость'/>
							<label htmlFor='exam745' className={s.label}>
							Судимость
							</label>
						</div>

						<p className={s.errorText2}>
							{isError74 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(25))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 26 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step26;
