
import cn from 'classnames';
import s from '../steps.module.scss';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const Step8 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError22, setError22] = useState(false);
	const [isError23, setError23] = useState(false);
	const [isError24, setError24] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema22 = yup
		.object({
			['Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?']: yup.string(),
		})
		.required();


	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema22),
		defaultValues: {
			['Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?']: data['Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?'],
			['Какие функции у кнопки «Вызвать скрипт»?']: data['Какие функции у кнопки «Вызвать скрипт»?'],
			['Если исполнители сплоховали на заказе, что делать в данном случае?']: data['Если исполнители сплоховали на заказе, что делать в данном случае?'],
		},
	});
	
	/* const ckbox22 = watch('Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?'); */
	const ckbox23 = watch('Какие функции у кнопки «Вызвать скрипт»?');
	const ckbox24 = watch('Если исполнители сплоховали на заказе, что делать в данном случае?');
	
	/* useEffect(() => {
		if (!ckbox22?.length) {
			setError22(true);
		} else {
			setError22(false);
		}
	}, [ckbox22]); */

	useEffect(() => {
		if (!ckbox23?.length) {
			setError23(true);
		} else {
			setError23(false);
		}
	}, [ckbox23]);

	useEffect(() => {
		if (!ckbox24?.length) {
			setError24(true);
		} else {
			setError24(false);
		}
	}, [ckbox24]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError23 && !isError24) {
			dispatch(setAddStep(9));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(8));
		}
	};

	return (
		<div className={s.step8}>
      <div className={s.wrapper_heading}>
			  <h5>Роли. Директор. Skilla</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				

			<div className={s.form_item}>
					<h4 className={s.form_title}>22. Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?</h4>

					<div className={s.question22}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question22,
								})}
								{...register('Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?')}
								id='exam221'
								type='radio'
								name='Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?'
								autoComplete='off'
								value='Да, Бизнес-ассистенты рассматривают договоры и дают рекомендации'
							/>

							<label htmlFor='exam221' className={s.label}>
							Да, Бизнес-ассистенты рассматривают договоры и дают рекомендации
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question22,
								})}
								{...register('Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?')}
								id='exam222'
								type='radio'
								name='Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?'
								autoComplete='off'
								value='Да, Бизнес-ассистенты рассматривают договоры и составляют протокол разногласий'
							/>
							<label htmlFor='exam222' className={s.label}>
							Да, Бизнес-ассистенты рассматривают договоры и составляют протокол разногласий
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question22,
								})}
								{...register('Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?')}
								id='exam223'
								type='radio'
								name='Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?'
								autoComplete='off'
								value='Нет, проверкой договоров отдел не занимается'
							/>
							<label htmlFor='exam223' className={s.label}>
							Нет, проверкой договоров отдел не занимается
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Можно ли направить договор с заказчиком на проверку в чат поддержки Бизнес-ассистентов?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>23. Какие функции у кнопки «Вызвать скрипт»?</h4>
                    <div className={s.question23}>
						
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question23,
								})}
								{...register('Какие функции у кнопки «Вызвать скрипт»?')}
								id='exam231'
								type='checkbox'
								name='Какие функции у кнопки «Вызвать скрипт»?'
								autoComplete='off'
								value='Принимать входящие звонки, добавляет заказы в систему, отправляет юр. лицу договор и счет на предоплату'/>
							<label htmlFor='exam231' className={s.label}>
							Принимать входящие звонки, добавляет заказы в систему, отправляет юр. лицу договор и счет на предоплату
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question23,
								})}
								{...register('Какие функции у кнопки «Вызвать скрипт»?')}
								id='exam232'
								type='checkbox'
								name='Какие функции у кнопки «Вызвать скрипт»?'
								autoComplete='off'
								value='Принимать входящие и совершает исходящие звонки, обрабатывает заказы в системе'/>
							<label htmlFor='exam232' className={s.label}>
							Принимать входящие и совершает исходящие звонки, обрабатывает заказы в системе
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question23,
								})}
								{...register('Какие функции у кнопки «Вызвать скрипт»?')}
								id='exam233'
								type='checkbox'
								name='Какие функции у кнопки «Вызвать скрипт»?'
								autoComplete='off'
								value='Контролировать заказы и работать со всей первичной документацией'/>
							<label htmlFor='exam233' className={s.label}>
							Контролировать заказы и работать со всей первичной документацией
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question23,
								})}
								{...register('Какие функции у кнопки «Вызвать скрипт»?')}
								id='exam234'
								type='checkbox'
								name='Какие функции у кнопки «Вызвать скрипт»?'
								autoComplete='off'
								value='Консультировать о вакансиях'/>
							<label htmlFor='exam234' className={s.label}>
							Консультировать о вакансиях
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question23,
								})}
								{...register('Какие функции у кнопки «Вызвать скрипт»?')}
								id='exam235'
								type='checkbox'
								name='Какие функции у кнопки «Вызвать скрипт»?'
								autoComplete='off'
								value='Записывать на собеседование соискателей'/>
							<label htmlFor='exam235' className={s.label}>
							Записывать на собеседование соискателей
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question23,
								})}
								{...register('Какие функции у кнопки «Вызвать скрипт»?')}
								id='exam236'
								type='checkbox'
								name='Какие функции у кнопки «Вызвать скрипт»?'
								autoComplete='off'
								value='Предоставлять контакты потенциальных заказчиков'/>
							<label htmlFor='exam236' className={s.label}>
							Предоставлять контакты потенциальных заказчиков
							</label>
						</div>

						{/* <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question23,
								})}
								{...register('Какие функции у кнопки «Вызвать скрипт»?')}
								id='exam237'
								type='checkbox'
								name='Какие функции у кнопки «Вызвать скрипт»?'
								autoComplete='off'
								value='Проводит холодный обзвон'/>
							<label htmlFor='exam237' className={s.label}>
							Проводит холодный обзвон
							</label>
						</div> */}

						<p className={s.errorText2}>
							{isError23 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
					

					<p className={s.errorText}>
						{errors['Чем занимается отдел дизайнеров?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>24. Если исполнители сплоховали на заказе, что делать в данном случае?</h4>
                    <div className={s.question24}>
						
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question24,
								})}
								{...register('Если исполнители сплоховали на заказе, что делать в данном случае?')}
								id='exam241'
								type='checkbox'
								name='Если исполнители сплоховали на заказе, что делать в данном случае?'
								autoComplete='off'
								value='Игнорировать звонки клиента'/>
							<label htmlFor='exam241' className={s.label}>
							Игнорировать звонки клиента
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question24,
								})}
								{...register('Если исполнители сплоховали на заказе, что делать в данном случае?')}
								id='exam242'
								type='checkbox'
								name='Если исполнители сплоховали на заказе, что делать в данном случае?'
								autoComplete='off'
								value='Нейтрализовать конфликтную ситуацию'/>
							<label htmlFor='exam242' className={s.label}>
							Нейтрализовать конфликтную ситуацию
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question24,
								})}
								{...register('Если исполнители сплоховали на заказе, что делать в данном случае?')}
								id='exam243'
								type='checkbox'
								name='Если исполнители сплоховали на заказе, что делать в данном случае?'
								autoComplete='off'
								value='Стараться максимально мирно решить текущий вопрос с клиентом'/>
							<label htmlFor='exam243' className={s.label}>
							Стараться максимально мирно решить текущий вопрос с клиентом
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question24,
								})}
								{...register('Если исполнители сплоховали на заказе, что делать в данном случае?')}
								id='exam244'
								type='checkbox'
								name='Если исполнители сплоховали на заказе, что делать в данном случае?'
								autoComplete='off'
								value='Не повышать голос на клиента или исполнителей, не переходить на личность'/>
							<label htmlFor='exam244' className={s.label}>
							Не повышать голос на клиента или исполнителей, не переходить на личность
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question24,
								})}
								{...register('Если исполнители сплоховали на заказе, что делать в данном случае?')}
								id='exam245'
								type='checkbox'
								name='Если исполнители сплоховали на заказе, что делать в данном случае?'
								autoComplete='off'
								value='Не поддаваться провокации сторон, сохранять спокойствие и равновесие'/>
							<label htmlFor='exam245' className={s.label}>
							Не поддаваться провокации сторон, сохранять спокойствие и равновесие
							</label>
						</div>

						<p className={s.errorText2}>
							{isError24 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(7))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 8 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step8;
